import { format } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const formatDateString = (
  dateString,
  dateFormat = DEFAULT_DATE_FORMAT
) => {
  const date = dateString ? new Date(dateString) : new Date();
  return format(date, dateFormat);
};
