import { useContext, useEffect, useState } from 'react';
import { getPbiDashboard } from '../services/userServices';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { ErrorContext } from '../ErrorContext';

const EmbedDashboard = () => {
  const { setErrorAlert } = useContext(ErrorContext);
  const [embedConfig, setEmbedConfig] = useState(null);

  useEffect(() => {
    getPbiDashboard()
      .then((res) => {
        setEmbedConfig({
          ...res.data.embed_config,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.Read,
          settings: {
            ...res.data.embed_config.settings,
          },
        });
      })
      .catch((error) => {
        setErrorAlert({ error: error });
      });
  }, []);

  return (
    <div className="bg-background-gray h-full flex text-dark-text">
      {embedConfig && (
        <PowerBIEmbed
          cssClassName="w-full h-full p-6"
          embedConfig={embedConfig}
        />
      )}
    </div>
  );
};

export default EmbedDashboard;
