import { memo, useEffect, useState } from 'react';
import { applyFilters } from '../../../helpers/tableFiltersHelper.js';
import OutlineIconButton from '../../common/OutlineIconButton.jsx';
import refreshIcon from '../../../assets/icons/refresh_icon.svg';

const ActiveFilters = memo(function ActiveFilters({
  status,
  gridApi,
  showRefreshIcon = false,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!status.length) {
      setIsOpen(false);
    }
  }, [status]);

  return (
    <div
      data-testid="applied-filters"
      className="applied-filters order-first pl-[10px]"
    >
      <div className="h-11 flex items-center">
        <span className="ag-group-title-bar-icon ag-filter-toolpanel-group-title-bar-icon">
          <span
            data-testid="dropdown-arrow"
            className={`ag-icon ${
              isOpen ? 'ag-icon-tree-open' : 'ag-icon-tree-closed'
            }`}
            onClick={() => setIsOpen(!isOpen)}
          />
        </span>
        <span>Applied Filters</span>
        {!!status.length && (
          <span
            className="flex items-center justify-center
        h-4 w-4 ml-1.5 rounded-full bg-purple-vs text-white"
          >
            {status.length}
          </span>
        )}
        {showRefreshIcon && (
          <OutlineIconButton
            tooltip="Re-Apply Filters"
            outlineColor="border-gray-vs"
            bgClass="bg-white"
            padding=""
            icon={refreshIcon}
            widthClass="w-[26px]"
            heightClass="h-[26px]"
            margin="ml-auto mr-2"
            onClick={() => applyFilters(gridApi)}
          />
        )}
      </div>
      {status.length > 0 && isOpen && (
        <div className="pl-[15px] pr-2.5">
          <ul>
            {status.map((filter, i) => (
              <li key={i} className="mb-1">
                {`\u2022 ${filter}`}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!status.length && isOpen && (
        <div className="mb-1 pl-[15px] pr-2.5">No active filters</div>
      )}
    </div>
  );
});

export default ActiveFilters;
