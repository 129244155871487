export default function JobActionPanel() {
  return (
    <div className="flex mx-4" id="status-container">
      <div className="flex flex-grow my-4 h-[30px] items-center">
        <div
          className="flex   pr-4 flex-grow "
          id="actions-dropdown-placeholder"
        />
        <div
          className="flex pr-4 flex-grow justify-start"
          id="notification-placeholder"
        />
        <div className="flex flex-grow pr-4 " id="protocol-placeholder" />
        <div className="flex m justify-end" id="undo-section-placeholder" />
      </div>
      <div
        className="flex justify-end items-center w-[250px] mr-[38px] px-1"
        id="right-section-placeholder"
      />
    </div>
  );
}
