import { useCallback, useEffect, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { ResetFilterButton } from './ResetFilterButton';
import { DateInput } from './DateInput';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper.js';
import { defaultNumberCompareOperators } from '../../../constants/constants';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateString } from '../../../helpers/dateHelpers.js';

const filterOperators = defaultNumberCompareOperators;
export default ({
  api,
  colDef,
  getValue,
  model,
  onModelChange,
  onModelModify,
  clientSide,
}) => {
  const [filterDate1, setFilterDate1] = useState(null);
  const [filterOperator1, setFilterOperator1] = useState(filterOperators[0]);
  const [filterDate2, setFilterDate2] = useState(null);
  const [filterOperator2, setFilterOperator2] = useState(filterOperators[0]);

  useEffect(() => {
    if (!filterDate1) {
      setFilterDate2(null);
      setFilterOperator2(filterOperators[0]);
    }
  }, [filterDate1]);

  // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
  useEffect(() => {
    const newModel = isFilterActive()
      ? {
          values: getFilter(filterDate1, filterOperator1).concat(
            getFilter(filterDate2, filterOperator2)
          ),
          type: 'date',
          label: colDef.headerName,
        }
      : null;

    if (clientSide) {
      onModelChange(newModel);
    } else {
      api.setColumnFilterModel(colDef.field, newModel);
      onModelModify();
    }
  }, [filterDate1, filterOperator1, filterDate2, filterOperator2]);

  // handle global Clear Filters
  useEffect(() => {
    if (!model) {
      resetFilter();
    }
  }, [model]);

  if (clientSide) {
    const filterPass = (value, filterOperator, filterDate) => {
      switch (filterOperator) {
        case 'eq':
          return value === filterDate;
        case 'lt':
          return value < filterDate;
        case 'lte':
          return value <= filterDate;
        case 'gt':
          return value > filterDate;
        case 'gte':
          return value >= filterDate;
        default:
          return false;
      }
    };

    const doesFilterPass = useCallback(
      ({ node }) => {
        const value = getValue(node);
        const filterOperator1 = model?.values[0]?.operator;
        const filterOperator2 = model?.values[1]?.operator;
        const filterDate1 = model?.values[0]?.value;
        const filterDate2 = model?.values[1]?.value;
        return (
          filterPass(value, filterOperator1, filterDate1) &&
          (!filterDate2 || filterPass(value, filterOperator2, filterDate2))
        );
      },
      [model]
    );

    // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
    useGridFilter({ doesFilterPass });
  }

  const isFilterActive = () => {
    return filterDate1 != null && filterDate1 !== '';
  };

  const resetFilter = useCallback(() => {
    setFilterDate1(null);
    setFilterOperator1(filterOperators[0]);
    setFilterDate2(null);
    setFilterOperator2(filterOperators[0]);
  }, []);

  const getFilter = (dateStr, operator) => {
    if (dateStr) {
      return [
        {
          value: formatDateString(dateStr),
          operator: operator.value,
          operatorLabel: operator.label.toLowerCase(),
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div
      id="table-date-filter"
      onKeyDown={({ key }) => !clientSide && applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <DateInput
        options={filterOperators}
        selectValue={filterOperator1}
        onSelectChange={setFilterOperator1}
        inputValue={filterDate1}
        onInputChange={setFilterDate1}
        id="table-date-filter-wrapper1"
      />
      {filterDate1 && (
        <>
          <div className="text-center">AND</div>
          <DateInput
            options={filterOperators}
            selectValue={filterOperator2}
            onSelectChange={setFilterOperator2}
            inputValue={filterDate2}
            onInputChange={setFilterDate2}
            id="table-date-filter-wrapper2"
          />
        </>
      )}
    </div>
  );
};
