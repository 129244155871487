import { axiosInstance, axiosSecureInstance } from './apiSecure';
export async function loginUser(user, pass) {
  return await axiosInstance.post('auth/login', {
    email: user,
    password: pass,
  });
}

export async function refreshToken(token) {
  return await axiosInstance.post(
    'auth/refresh',
    {},
    { headers: { Authorization: getAuthorizationHeader(token) } }
  );
}
//token, config
export async function getUserDetails(userId) {
  return await axiosSecureInstance.get(`/users/${userId}`);
}

export async function createUser(data) {
  return await axiosSecureInstance.post('/users', data);
}

export async function updateUser(userId, params) {
  return await axiosSecureInstance.put(`/users/${userId}`, params);
}

export async function getUsers() {
  return await axiosSecureInstance.get('/users');
}

export async function changeUserStatus(userId, newStatus) {
  return await axiosSecureInstance.put(`/users/${userId}`, {
    active: newStatus,
  });
}

export async function getPbiDashboard() {
  return await axiosSecureInstance.get('/rc_pbi_dashboard');
}

export function getAuthorizationHeader(token) {
  return `Bearer ${token}`;
}
