import { memo } from 'react';
import dollarSign from '../../assets/icons/dollar-circle-sign.png';
import invoice from '../../assets/icons/invoice.png';

const MONEY_DISPLAY_VARIANTS = {
  earned: {
    icon: dollarSign,
    iconAltText: 'Dollar Sign Icon',
    suffixText: 'earned',
    tooltip:
      'This value is calculated based on the initial code count. Final invoiced amount may differ.',
    heightClass: 'h-[38px]',
    colorClass: 'green-money-display',
    animationClass: 'button-shine-effect',
  },
  available: {
    icon: invoice,
    iconAltText: 'Invoice Icon',
    suffixText: 'available',
    tooltip:
      'This is the total amount of money available from all currently unclaimed jobs.',
    heightClass: 'h-[32px]',
    colorClass: 'blue-money-display',
    animationClass: '',
  },
};

function formatCurrency(value = 0) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

const MoneyDisplay = memo(function MoneyDisplay({ value, variant }) {
  const {
    icon,
    iconAltText,
    suffixText,
    tooltip,
    heightClass,
    colorClass,
    animationClass,
  } = MONEY_DISPLAY_VARIANTS[variant] || MONEY_DISPLAY_VARIANTS.earned;

  return (
    <div
      className={`${animationClass} ${heightClass} group relative mr-3`}
      data-testid={`money-display-${variant}`}
    >
      <div
        className={`h-full px-2 border flex items-center rounded-md ${colorClass}`}
      >
        <img src={icon} alt={iconAltText} className="pr-1 inline" />
        <div className="text-sm flex items-center">
          <span className="bold text-2xl">{formatCurrency(value)}</span>
          <span className="text font-bold ml-1">{suffixText}</span>
        </div>
      </div>
      <div className="money-display-tooltip">
        <div className="status-tooltip-arrow" />
        <div>{tooltip}</div>
      </div>
    </div>
  );
});

export default MoneyDisplay;
